import React from 'react'
import {
    AppBar,
    Toolbar,
    Box,
    List,
    ListItem,
    Typography,
    styled,
    ListItemButton,
    ListItemText,
} from '@mui/material';
// menu
import DrawerItem from './DrawerItem';
// rotas
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { authenticationSlice, signOut, logIn } from '../redux/slices/authentication'

// personalizacao
const StyledToolbar = styled(Toolbar)({
    display: 'flex',
    justifyContent: 'space-between',
});

const ListMenu = styled(List)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up("sm")]: {
        display: "flex",
    },
}));

//rotas
const itemList = [
    {
        text: "Home",
        to: "/"
    },
    {
        text: "About",
        to: "/about"
    },
    {
        text: "Labs",
        to: "/labs"
    }
];


const Navbar = () => {
    const dispatch = useDispatch()
    const loginStatus = useSelector((state) => state.isLoggedIn)
    const isAdmin = useSelector((state) => state.isAdmin);

    return (
        <AppBar
            component="nav"
            position="sticky"
            sx={{
                backgroundColor: 'orange',
            }}
            elevation={5}
        >
            <StyledToolbar>
                <Typography
                    variant="h6"
                    component="h2"

                >
                    Neurometrics.ai
                </Typography>
                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <DrawerItem />
                </Box>
                <ListMenu>
                    {itemList.map((item) => {
                        const { text } = item;
                        return (
                            <ListItem key={text}>
                                <ListItemButton component={Link} to={item.to}
                                    sx={{
                                        color: '#fff',
                                        "&:hover": {
                                            backgroundColor: 'transparent',
                                            color: '#1e2a5a',
                                        }
                                    }}
                                >
                                    <ListItemText primary={text} />
                                </ListItemButton>


                            </ListItem>
                        )
                    })}

                    {!loginStatus && (
                        <ListItem key="Login">
                            <ListItemButton sx={{
                                color: '#fff',
                                "&:hover": {
                                    backgroundColor: 'transparent',
                                    color: '#1e2a5a',
                                }
                            }}
                                component={Link} to="/login">
                                <ListItemText primary="Login" />
                            </ListItemButton>
                        </ListItem>
                    )}

                    {isAdmin && (
                        <ListItem key="Admin">
                            <ListItemButton sx={{
                                color: '#fff',
                                "&:hover": {
                                    backgroundColor: 'transparent',
                                    color: '#1e2a5a',
                                }
                            }}
                                component={Link} to="/admin">
                                <ListItemText primary="Admin" />
                            </ListItemButton>
                        </ListItem>
                    )}
                    

                    {loginStatus && (
                        <ListItem key="Signout">
                            <ListItemButton sx={{
                                color: '#fff',
                                "&:hover": {
                                    backgroundColor: 'transparent',
                                    color: '#1e2a5a',
                                }
                            }}
                                onClick={() => dispatch(signOut())}>
                                <ListItemText primary="Signout" />
                            </ListItemButton>
                        </ListItem>
                    )}

                </ListMenu>
            </StyledToolbar>
        </AppBar>
    )
}

export default Navbar;
