import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { useSelector, useDispatch } from 'react-redux';
import { logIn } from '../redux/slices/authentication';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

const Login = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [message, setMessage] = useState('');
  const loginStatus = useSelector((state) => state.isLoggedIn);

  const responseMessage = async (response) => {
    const formData = new FormData();
    formData.append('username', 'dummy');
    formData.append('password', response["credential"]);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/token`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        dispatch(logIn(response.data));
        navigate('/labs', { replace: true });
        // Handle successful login here, e.g., store the token, redirect, etc.
      }
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.detail;
        setMessage(errorMessage);
      } else {
        setMessage('Network error');
      }
    }
  };

  const errorMessage = (error) => {
    console.log(error);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} style={{ padding: '2rem', marginTop: '2rem', textAlign: 'center' }}>
        {loginStatus ? (
          <Alert severity="success">You are already logged in!</Alert>
        ) : (
          <Box>
            <Typography variant="h5" component="h1" gutterBottom>
              {!message ? "" : message}
            </Typography>
            <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
            <Typography variant="body2" style={{ marginTop: '1rem' }}>
              By signing in, you agree to our <Link to="/privacy_policy">Privacy Policy</Link>.
            </Typography>
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default Login;
