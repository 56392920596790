import { Typography, Button, CircularProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';


const Admin = () => {
    const [unapprovedLabs, setUnapprovedLabs] = useState([]);
    const [loading, setLoading] = useState(false);
    const sessionId = useSelector((state) => state.sessionId);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        fetchUnapprovedLabs();
    }, []);

    const fetchUnapprovedLabs = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${backendUrl}/v1/get_unapproved_labs`, {
                headers: {
                    'Authorization': `Bearer ${sessionId}`
                }
            });
            setUnapprovedLabs(response.data);
        } catch (error) {
            console.error('Error fetching unapproved labs:', error);
            // Handle error as needed
        } finally {
            setLoading(false);
        }
    };

    const approveLab = async (email) => {
        setLoading(true);
        try {
            await axios.put(`${backendUrl}/v1/whitelist_lab/${email}`, null, {
                headers: {
                    'Authorization': `Bearer ${sessionId}`
                }
            });
            // After successful approval, you may want to update state or UI
            fetchUnapprovedLabs(); // Refresh the list after approval
        } catch (error) {
            console.error('Error approving lab:', error);
            // Handle error as needed
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Admin Panel - Approve Unapproved Emails
            </Typography>
            {loading && <CircularProgress />} {/* Show loading indicator */}
            {!loading && (
                <div>
                    {unapprovedLabs.map((lab) => (
                        <div key={lab.email}>
                            <Typography>{lab.email}</Typography>
                            <Button variant="contained" color="primary" onClick={() => approveLab(lab.email)}>
                                Approve
                            </Button>
                        </div>
                    ))}
                    {unapprovedLabs.length === 0 && <Typography>No unapproved emails found.</Typography>}
                </div>
            )}
        </div>
    );
};

export default Admin;
