import { configureStore, Tuple} from '@reduxjs/toolkit'
import { authenticationSlice } from './slices/authentication'
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import { thunk } from 'redux-thunk';
import logger from 'redux-logger';


const persistConfig = {
  key: 'root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, authenticationSlice.reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: () => new Tuple(thunk, logger),
})

export const persistor = persistStore(store);


