import React from 'react';
//rotas
import { BrowserRouter, Routes, Route } from 'react-router-dom';
//pages
import Home from './pages/Home';
import About from './pages/About';
//componentes
import Navbar from './components/Navbar';
import Login from './components/Login.js'
import Admin from './components/Admin.js'
import Labs from './components/Lab/Labs.js'
import PrivacyPolicy from './components/PrivacyPolicy'


function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/login' element={<Login />} />
          <Route path='/labs' element={<Labs />} />
          <Route path='/admin' element={<Admin />} />
          <Route path='/privacy_policy' element={<PrivacyPolicy />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;