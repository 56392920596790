import React, { useState, useRef } from 'react';
import { Typography, Button, Box, List, ListItem, ListItemText, CircularProgress, Snackbar, TextField } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import { useSelector } from 'react-redux';

const UploadData = ({ text, textAlign }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [remarks, setRemarks] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null);
    const [audioUrl, setAudioUrl] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const sessionId = useSelector((state) => state.sessionId);

    const mediaRecorderRef = useRef(null);
    const mediaStreamRef = useRef(null);
    const audioChunksRef = useRef([]);

    const handleFileChange = (event) => {
        setSelectedFiles([...event.target.files]);
    };

    const handleRemarksChange = (event) => {
        setRemarks(event.target.value);
    };

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaStreamRef.current = stream;
            mediaRecorderRef.current = new MediaRecorder(stream);
            audioChunksRef.current = [];

            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunksRef.current.push(event.data);
                }
            };

            mediaRecorderRef.current.onstop = () => {
                const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
                const audioUrl = URL.createObjectURL(audioBlob);
                setAudioBlob(audioBlob);
                setAudioUrl(audioUrl);
            };

            mediaRecorderRef.current.start();
            setIsRecording(true);
        } catch (error) {
            console.error('Error starting recording:', error);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
            mediaRecorderRef.current.stop();
        }
        if (mediaStreamRef.current) {
            mediaStreamRef.current.getTracks().forEach((track) => track.stop());
        }
        setIsRecording(false);
    };

    const handleRecord = () => {
        if (isRecording) {
            stopRecording();
        } else {
            startRecording();
        }
    };

    const handleUpload = async () => {
        setIsUploading(true);
        const formData = new FormData();
        selectedFiles.forEach(file => {
            formData.append('files', file);
        });
        formData.append('remarks', remarks);
        if (audioBlob) {
            formData.append('files', audioBlob, 'voice_note.webm');
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${sessionId}`
                },
                withCredentials: true
            });
            console.log('Data uploaded successfully:', response.data);
            setUploadSuccess(true);
            setSelectedFiles([]);
            setRemarks('');
            setAudioBlob(null);
            setAudioUrl('');
        } catch (error) {
            console.error('Error uploading data:', error);
            setUploadError(true);
        } finally {
            setIsUploading(false);
        }
    };

    const handleClose = () => {
        setUploadSuccess(false);
        setUploadError(false);
    };

    return (
        <Box sx={{ textAlign: textAlign, p: 3 }}>
            <Typography variant="h4" component="h3" sx={{ fontWeight: '700', mb: 2 }}>
                {text}
            </Typography>
            <TextField
                fullWidth
                label="Remarks"
                multiline
                rows={4}
                value={remarks}
                onChange={handleRemarksChange}
                sx={{ mb: 2 }}
            />
            <Button variant="contained" component="label" sx={{ mb: 2, mr: 2 }}>
                Upload Files
                <input type="file" multiple hidden onChange={handleFileChange} />
            </Button>
            <Button
                variant="contained"
                color={isRecording ? "secondary" : "primary"}
                onClick={handleRecord}
                sx={{ mb: 2 }}
            >
                {isRecording ? "Stop Recording" : "Record Voice Note"}
            </Button>
            {audioUrl && (
                <Box sx={{ mb: 2 }}>
                    <audio controls src={audioUrl} />
                </Box>
            )}
            {(selectedFiles.length > 0 || audioBlob) && (
                <Box>
                    <Typography variant="h6" component="h4" sx={{ fontWeight: '600', mb: 2 }}>
                        Selected Files:
                    </Typography>
                    <List>
                        {selectedFiles.map((file, index) => (
                            <ListItem key={index}>
                                <ListItemText primary={file.name} />
                            </ListItem>
                        ))}
                        {audioBlob && (
                            <ListItem>
                                <ListItemText primary="Recorded Voice Note" />
                            </ListItem>
                        )}
                    </List>
                    <Button variant="contained" color="primary" onClick={handleUpload} disabled={isUploading}>
                        {isUploading ? 'Uploading...' : 'Upload to Server'}
                    </Button>
                    {isUploading && <CircularProgress size={24} sx={{ ml: 2 }} />}
                </Box>
            )}
            <Snackbar open={uploadSuccess} autoHideDuration={6000} onClose={handleClose}>
                <MuiAlert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Data uploaded successfully!
                </MuiAlert>
            </Snackbar>
            <Snackbar open={uploadError} autoHideDuration={6000} onClose={handleClose}>
                <MuiAlert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    Error uploading data.
                </MuiAlert>
            </Snackbar>
        </Box>
    );
};

export default UploadData;
