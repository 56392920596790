import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText } from '@mui/material';

const PrivacyPolicy = () => {
    return (
        <Container>
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Privacy Policy for Neurometrics.ai
                </Typography>
                <Typography variant="body1" paragraph>
                    Effective Date: July 3, 2024
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    Information We Collect
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>Personal Information:</strong> We only collect your email address when you sign in using Google OAuth.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    How We Use Your Information
                </Typography>
                <Typography variant="body1" paragraph>
                    We use the information we collect in the following ways:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="To provide and maintain our service: We use your email address to identify and authenticate your account." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="To communicate with you: We may use your email address to send you service-related notifications and updates." />
                    </ListItem>
                </List>

                <Typography variant="h5" component="h2" gutterBottom>
                    Sharing Your Information
                </Typography>
                <Typography variant="body1" paragraph>
                    We do not share, sell, rent, or trade your email address with third parties for their promotional purposes.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    Data Security
                </Typography>
                <Typography variant="body1" paragraph>
                    We implement reasonable measures to protect the security of your email address. However, please be aware that no security measures are perfect or impenetrable, and we cannot guarantee the security of your information.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    Your Choices
                </Typography>
                <Typography variant="body1" paragraph>
                    You may choose not to provide us with your email address. However, this may prevent you from using certain features of our service.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    Changes to This Privacy Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    Use of Google User Data
                </Typography>
                <Typography variant="body1" paragraph>
                    Neurometrics.ai's use and transfer of information received from Google APIs to any other app will adhere to Google API Services User Data Policy, including the Limited Use requirements.
                </Typography>
            </Box>
        </Container>
    );
};

export default PrivacyPolicy;
