import React from 'react';
import { Box, styled, Typography } from "@mui/material";

const Header = () => {

    const CustomBox = styled(Box)(({ theme }) => ({
        minHeight: '80vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(2),
        paddingTop: theme.spacing(10),
        backgroundColor: 'white',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            textAlign: 'center',
        }
    }));

    const BoxText = styled(Box)(({ theme }) => ({
        flex: '1',
        paddingLeft: theme.spacing(8),
        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    }));

    return (
        <CustomBox component='header'>
            <BoxText component='section'>
                <Typography
                    variant='h2'
                    component='h1'
                    sx={{
                        fontWeight: 700,
                        color: '#333',
                    }}
                >
                    Neurometrics.ai
                </Typography>

                <Typography
                    variant='subtitle1'
                    component='p'
                    sx={{
                        py: 3,
                        lineHeight: 1.6,
                        color: '#666',
                    }}
                >
                    AI-Driven Solutions for Smarter Healthcare
                </Typography>
            </BoxText>

            <Box
                sx={theme => ({
                    [theme.breakpoints.down('md')]: {
                        flex: '1',
                        paddingTop: theme.spacing(4),
                        alignSelf: 'center',
                    },
                    [theme.breakpoints.up('md')]: {
                        flex: '2',
                        alignSelf: 'flex-end',
                    },
                })}
            >
                {/* Add any additional elements or images here */}
            </Box>
        </CustomBox>
    );
};

export default Header;
