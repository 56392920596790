import { createSlice } from '@reduxjs/toolkit'
import session from 'redux-persist/lib/storage/session';

const initialState = {
  isLoggedIn: false,
  sessionId: null
}

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    signOut: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.isLoggedIn = false;
      state.sessionId = null;
      state.email = null;
      state.isAdmin = false
    },
    logIn: (state, action) => {
      state.isLoggedIn = true;
      state.sessionId = action.payload["access_token"];
      state.email = action.payload["email"];
      state.isAdmin = action.payload["admin"];
    },
  },
})

// Action creators are generated for each case reducer function
export const { signOut, logIn } = authenticationSlice.actions

export default authenticationSlice.reducer