import React from 'react'
import { useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import UploadData from './UploadData'


const Labs = () => {
    const loginStatus = useSelector((state) => state.isLoggedIn)
    if (!loginStatus) {
        return (<Alert severity="error">Please Login in before continuing. Also ensure your email is whitelisted.</Alert>)
    }
    return (
        <div>
            <UploadData />
        </div>
    )
}

export default Labs;